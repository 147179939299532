.image{
    width: 250px;
    height:200px;
    /*position: relative;
    top: -1em;*/
}
.p{
    margin-bottom: 0;
}
.x_close {
    /*position: relative;
    top: -7.5em;
    left: 0.3em;*/
    margin-left: 4px;
    width: 22px;
    height: 22px;
    background-color: var(--background);
}

.imageForCrop {
    border: 1px solid black;
    object-fit: contain;
}

.checkOk{
    position: relative;
    top: 0;
    left: 1em;
    width: 22px;
    height: 22px;
    color: green;
    background-color: var(--background);
}

.label{
    margin-bottom: 5px;
    font-size: 12px;
}
