.x_close {
    position: relative;
    left: 0px;
    top: 0px;
    width: 22px;
    height: 22px;
    background-color: var(--background);
}

.Rectangle {
    width: 795px;
    height: 55px;
    margin: 1px 2px 1px 0px;
    padding: 2px 1px 2px 8px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    display: inline;
}

.Rectangle:active, .Rectangle:focus, .Rectangle:hover, .Rectangle:focus-visible {
    box-shadow: none;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    outline-color: var(--text-variant);
}
.label{
    margin-bottom: 5px;
    font-size: 12px;
}



