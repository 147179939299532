
.Logo {
    width: 147px;
    height: 52px;
}

.nav {
    margin-left: 11.4%;
    margin-right: 11%;
    padding-top: 28px;
    height: 96px;
}

.bg{
    width: 100%;
    background-color: var(--light-94);
    padding-bottom: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
