.oddStripe,.evenStripe{
    margin-left: 0;
    padding-left: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.16px;
    color: #1d2621;

    background-color: var(--white);

}
.evenStripe{
    background-color: rgba(242, 171, 99, 0.1);
}



.numberAndName{
    padding-left: 30px;
    height: 65px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
}

.productList_btn_3 {
    width: 55px;
    height: 26px;
    margin: 2px 6px 2px 6px;
    padding: 2px 2px 2px 2px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    outline-color: transparent;
}

.productList_btn_3:active, .productList_btn_3:focus, .productList_btn_3:hover, .productList_btn_3:focus-visible {
    box-shadow: none;
    border: none;
    background: transparent;
    outline-color: transparent;
}


.productList1 {
    width: 30px;
    height: 30px;
    margin: 0 15px 0 0;
    border: solid 1px #b9b9b9;
    background-color: var(--white);
    text-align: center;
}


.productList1:focus{
    border: solid 1px blue;
    outline-color: blue;
}

.productList1.warning{
    border: solid 1px red;
}
.productList1.warning:focus{
    border: solid 1px red;
    outline-color: red;
}

.productName,.productNameInvisible{
    white-space: nowrap;
    overflow-x: auto;
}
.productNameInvisible{
    opacity: 0.5;
}
