.cont1, .cont2 {

    margin: 0 0;
    padding: 0 0 32px 20px;
    width: 100%;
    background-color: var(--white);
    font-size: var(--bakery-fs7);
    line-height: 1.5;
    color: var(--dark);
}
.cont2{
    padding-top: 35px;
    width: 40%;
    background-color: var(--primary-10);
}
.cont21{
    padding-top: 35px;
    width: 60%;
    background-color: var(--primary-10);
}
.hdr6{
    font-size: var(--bakery-fs6);
    background: transparent;
    width: 291px;
    border: none;
    font-weight: 600;
    line-height: 1.63;
}
.lab7{
    padding-top: 20px;
}

.inp1, .inpDays {
    width: 291px;
    height: 32px;
    padding: 0 0 0 10px;
    border-radius: 6px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}

.inpDays {
    padding: 5px 0 0 10px;
    height: auto;
}
.lab{
    font-size: var(--bakery-fs6);
    font-weight: 600;
    margin-bottom: 10px;

}
