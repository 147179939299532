.header{
    vertical-align: middle;
    text-align: left;
}
.header  th{
    font-size: var(--bakery-fs6);
    white-space: nowrap;
}

.tableBody{
    font-size: var(--bakery-fs7);
    vertical-align: middle;
    height: 46px;
}
/*
.tableBody td{
    vertical-align: center;
}
*/
