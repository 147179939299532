.systemSettings{
    font-size: var(--bakery-fs7);
    padding-left: 16px;
    padding-bottom: 80px;
}

.text1 {
    height: 26px;
    font-size: var(--bakery-fs5);
    font-weight: 600;
    line-height: 1.63;
    color: var(--dark);
}

.rec1{
    display: flex;
    margin-top: 8px;
}
.text2 {
    width: 255px;
    margin-top: 0;
}

.liText1,.text3{
    margin-top: 16px;
    width: 255px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}
.text3 {
    width: 85px;
}

.liTextRectangle{
    display: flex;
}

.liText,.liTextCode,.liTextPrice{
    height: 40px;
    margin-top: 8px;
    padding-left: 16px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    text-align: left;
}
.liText{
    width: 255px;
}
.liTextCode{
    width: 85px;
}
.liTextPrice{
    width: 85px;
}