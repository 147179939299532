
.liTextInput {
    width: 255px;
    height: 40px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background-color: var(--surface);
    color: var(--text);
    padding-left: 8px;
}
.liTextInput:active, .liTextInput:focus, .liTextInput:hover, .liTextInput:focus-visible
{
    box-shadow: none;
    /*border:none;*/
    background-color: var(--surface);
    outline-color: var(--text-variant);
}

.liText1,.text3{
    margin-top: 16px;
    width: 255px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    text-align: center;
}
.text3 {
    width: 85px;
}
.unit{
    position: relative;
    width: 0; /* don't touch this!*/
    font-size: var(--bakery-fs7);
    top: 20px;
    left: -58px;
}


