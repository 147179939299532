.x_close {
    width: 22px;
    height: 22px;
    margin: auto 5px auto 0;
    background-color: transparent;
}

.dayQuantity {
    width: 55px;
    margin-right: 7px;
}

/*+ selectType  ####################*/
.selectTypeContainer {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 8px;

    display: flex;
}

.selectTypeContainer  .dropdown-menu.show{

}

.selectType, .selectTypeWidth {
    width: 130px;
    height: 40px;
    padding-left: 12px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}


.selectType {
    display: flex;
    justify-content: space-between;
}

.selectType span {
    width: 85%;
    height: 26px;
    padding-left: 16px;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.36px;
    align-self: center;
    color: #1d2621;
}

.selectType img {
    align-self: center;
    margin: 5px;
}


/*- selectType  ####################*/
.myInputNumber {
    width: 80px;
    height: 40px;
    margin-left: 10px;
    padding: 0 12px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}

.myInputNumber input:active,
.myInputNumber input:focus,
.myInputNumber input:hover {
    box-shadow: none;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    outline-color: var(--danger);
}

.label{
    margin-bottom: 5px;
    font-size: 12px;
}
.unit{
    position: relative;
    width: 0; /* don't touch this!*/
    top: 10px;
    left: -15px;
}
