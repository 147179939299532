.x_close {
    display:inline;
    position: relative;
    left: -1.3em;
    top: 1em;
    width: 22px;
    height: 22px;
    background-color: transparent;
}

.dropdown {
    display:inline;
    position:relative;
    left: -1.8em;
    top: 0px;
    width: 22px;
    height: 22px;
    background-color: transparent;
    color: var(--text);
    box-shadow: none;
    border:none;
}
.dropdown:active, .dropdown:focus, .dropdown:hover, .dropdown:focus-visible
{
    box-shadow: none;
    border:none;
    background: var(--surface);
    outline-color: var(--text-variant);
}
.dayQuantity, .daysOfWeek{
    width: 66px;
    height: 40px;
    font-size: var(--bakery-fs6);
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    margin-right: 5px;

}
.daysOfWeek{
    height: auto;
    font-size: var(--bakery-fs7);
    border: none;
    background-color: transparent;
    margin-bottom: 5px;
}
.dayQuantity {
    text-align: end;
    padding: 0 22px 0 0;
}
.unit{
    position: relative;
    width: 0; /* don't touch this!*/
    font-size: var(--bakery-fs7);
    top: 12px;
    left: -24px;
}
