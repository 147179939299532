input>.rectangle{
    width: 190px;
    height: 55px;
    font-size: var(--bakery-fs6);
    padding: 0 0 0 12px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    color: var(--dark);
}
.rectangle:active, .rectangle:focus, .rectangle:hover, .rectangle:focus-visible
{
    box-shadow: none;
    border-radius: 4px;
    /*border: solid 1px var(--muted);*/
    background-color: var(--white);
    outline-color: var(--danger);
}

.label{
    margin-bottom: 5px;
    font-size: 12px;
}

