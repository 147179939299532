.daysName {
    margin-left: 0;
}

.x_close {
    width: 22px;
    height: 22px;
    background-color: transparent;
}


.dropdown {
    display:inline;
    position:relative;
    left: -1.8em;
    top: 0;
    width: 22px;
    height: 22px;
    background-color: transparent;
    color: var(--text);
    box-shadow: none;
    border:none;
}
.dropdown:active, .dropdown:focus, .dropdown:hover, .dropdown:focus-visible
{
    box-shadow: none;
    border:none;
    background: var(--surface);
    outline-color: var(--text-variant);
}

.typeContainer {
    display: flex;
    width: 155px;
    height: 55px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}
.typeContainer span {
    width: 80%;
    padding-left: 10px;
    align-self: center;
}
.typeContainer img {
    width: 22px;
    height: 22px;
    align-self: center;
}

.selectContainer {
    display: block;
    width: 750px;
    margin-top: 36px;
}
.selectContainer > div {
}

.daysRow{
    display: inline-flex;
}

.daysRow > span{
    width: 250px;
    text-wrap: none;
}

.selectTypes{
    width: 250px;
    height: 40px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}
.fromTo{
    margin: auto 8px 0 0;
    font-size: var(--bakery-fs7);
}

.inputField {
    width: 99px;
    height: 40px;
    margin-top: 6px;
    padding-left: 15px;

    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}