
.wrapper {
    margin-top: 8px;
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.leftMenu {
    width: 21.75%; /* corresponding  with ProductPage.module.css .contentWrapper{width: 78.25%;} */
    padding-left: 10.75%;
    padding-right: 10px;
    background-color: var(--light-50);
}

.contentWrapper {
    display: flex;
    width: 100%;
    margin-left: 1.75%;
}

.contentArea {
    width: 89.25%;
}
.ordersContentArea,.quaContentArea,.dirContentArea  {
    width: 100%;
    margin-left: 0;
}


@media (max-width: 1440px) {
    .contentArea {
        width: 95%;
    }
}
@media (max-width: 1200px) {
    .contentArea {
        width: 100%;
    }
}

.bodyArea {
    background-color: var(--white);
}

.footer {
    width: 100%;
    background-color: #198754;
}


/******************/


