.circle, .circleSelected, .circleM0{
    width: 12px;
    height: 12px;
    margin: 4px 10px auto 0;
    border: solid 1px var(--primary);
    border-radius: 50%;
    cursor: pointer;

}
.circleSelected{
    border: solid 3px var(--primary);
}
.x_close {
    width: 22px;
    height: 22px;
    margin: 8px 14px 14px 8px;
    background-color: transparent;
}

