@import "./main.css";

:root {
    --secondary: #314b9f;
    --muted: #bdbdbd;
    --dark: #2c1902;
    --white: #fff;
    --warning: #f26363;
    --light: #fefbf8;
    --primary: #f2ab63;
    --danger: #ff0000;
    --light-50: #fffdfc;
    --primary-10: rgba(242, 171, 99, 0.1);
    --light-90: rgba(248, 246, 243, 0.9);
    --light-94: rgba(248, 246, 243, 0.94);
    --muted-30: rgba(189, 189, 189, 0.3);
    --muted-3: rgba(189, 189, 189, 0.03);
    --white-50: rgba(255, 255, 255, 0.5);
    --warning-60: rgba(242, 99, 99, 0.6);
    --dark-60: rgba(44, 25, 2, 0.6);
    --dark-50: rgba(44, 25, 2, 0.5);
    --dark-10: rgba(44, 25, 2, 0.1);
    --secondary-60: rgba(49, 75, 159, 0.6);
    --primary-5: rgba(242, 171, 99, 0.05);
    --primary-50: rgba(242, 171, 99, 0.5);
    --secondary-3: rgba(49, 75, 159, 0.03);
    --secondary-10: rgba(49, 75, 159, 0.1);

    --aaaa: #d4d4d4;


    --text: var(--dark);/**/
    --text-variant: var(--warning);/**/
    --surface: var(--white);/**/
    --background: var(--light);/**/
    --background-header: var(--light-94);/**/


    --bakery-fs1: 50px;
    --bakery-fs2: 26px;
    --bakery-fs21: 28px;
    --bakery-fs3: 20px;
    --bakery-fs31: 22px;
    --bakery-fs4: 18px;
    --bakery-fs5: 16px;
    --bakery-fs6: 14px;
    --bakery-fs7: 12px;

    --header-h: 110px;
    --body-w: 1110px;
}

#root {
    display: block;
    overflow: hidden;
    height: 100vh;
    /*flex-direction: column;
    align-items: center;*/
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--background);
    overflow: hidden;
}

/*+ Bakery buttons ############################################*/
.bakery-bnt-yes, .bakery-bnt-no, .bakery-bnt-red,
.bakery-bnt-gray, .bakery-bnt-print, .bakery-bnt-to_pdf,
.bakery-bnt-export
{
    width: 160px;
    height: 40px;
    margin-top: 3px;
    margin-bottom: 10px;
    border-radius: 6px;
    font-size: var(--bakery-fs6);
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
}

.bakery-bnt-yes {
    background-color: var(--secondary);
    color: var(--white);
}
.bakery-bnt-print {
    background-color: var(--secondary);
    color: var(--white);
    border: none;
}
.bakery-bnt-to_pdf {
    background-color: var(--primary);
    color: var(--white);
    border: none;
}
.bakery-bnt-yes:hover{
    background-image: linear-gradient(to bottom, rgba(49, 75, 159, 0.5), rgba(0, 0, 0, 0.3)), linear-gradient(to bottom, var(--secondary), var(--secondary));
}

.bakery-bnt-no {
    border: solid 1px var(--muted);
    background-color: var(--white);
    color: var(--dark);
}
.bakery-bnt-no:hover {
    border: solid 1px var(--muted);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), linear-gradient(to bottom, var(--white), var(--white));
}
.bakery-bnt-red, .bakery-bnt-gray {
    border: none;
    background-color: var(--warning);
    color: var(--white);
}
.bakery-bnt-gray{
    background-color: var(--muted);
    cursor: default;
}

.bakery-bnt-red:hover {
    background-image: linear-gradient(to bottom, rgba(242, 99, 99, 0.51), rgba(0, 0, 0, 0.3)), linear-gradient(to bottom, var(--warning), var(--warning));
}
/*- Bakery buttons ###############################################*/

.form-control.Rectangle-m {
    width: 380px;
    height: 55px;
    margin: 1px 2px 1px 0;
    padding: 2px 1px 2px 8px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background-color: var(--surface);
}


.form-control.Rectangle:active, .form-control.Rectangle:focus, .form-control.Rectangle:hover, .form-control.Rectangle:focus-visible,
.form-control.Rectangle-s:active, .form-control.Rectangle-s:focus, .form-control.Rectangle-s:hover, .form-control.Rectangle-s:focus-visible,
.form-control.Rectangle-m:active, .form-control.Rectangle-m:focus, .form-control.Rectangle-m:hover, .form-control.Rectangle-m:focus-visible,
.Rectangle-m:active, .Rectangle-m:focus, .Rectangle-m:hover, .Rectangle-m:focus-visible {
    box-shadow: none;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    outline-color: var(--text-variant);
}

.form-control.product-list-1 {
    box-shadow: none;
    border: none;
    background: transparent;
    outline-color: transparent;
}

.form-control.product-list-1:active, .form-control.product-list-1:focus, .form-control.product-list-1:hover, .form-control.product-list-1:focus-visible {
    box-shadow: none;
    border: none;
    background: transparent;
    outline-color: transparent;
}


/***************************************************************************/
/*+ text-only ######################################*/
/***************************************************************************/
.button-text-only{
    margin: 1px auto 1px 0;
    width: 130px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--bakery-fs7);
    line-height: 1.33;
    letter-spacing: normal;

    border-radius: 6px;
    background-color: var(--muted-30);
    color: var(--dark);
    cursor: pointer;
}

.left-menu, .left-menu-act{
    margin: 1px 2px 1px 16px;
    padding: 16px;
    opacity: 0.9;
    box-shadow: inset 0 -1px 0 0 rgba(203, 203, 203, 0.5);
    font-size: var(--bakery-fs6);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--dark);
    cursor: pointer;
    white-space: nowrap;
}

.left-menu-act{
    font-weight: 600;
}

.button-text-only:hover{
    border-radius: 6px;
    box-shadow: 0 0 4px 0 var(--primary-50);
    border: solid 1px var(--primary);
}

.button-text-only:disabled {
    background: transparent;
}

.color-text {
    color: var(--text)
}

.button-text-only-gray {
    margin: 1px 2px 1px 0;
    padding: 2px 1px 2px 0;
    border: none;
    background: transparent;
    color: var(--text-variant);
    transition: none;
}

.button-text-only-gray:disabled {
    background: transparent;
}

Button.btn-product-list-1 {
    border: none;
    background: transparent;
    color: var(--secondary);
}

.btn-product-list-1:hover, .btn-product-list-1:focus, .btn-product-list-1:active {
    border: none;
    box-shadow: none;
    background: var(--surface);
    color: var(--secondary);
    outline-color: transparent;
}

/*- text-only ######################################*/

/*+ dropdown*/
.dropdown-toggle::after {
    all: initial;
}
/*- dropdown*/

.my-span-4-image {
    color: var(--secondary);
    padding: 0;
}

.daysName {
    margin-left: -1em;
}


/***************************************************************************/
/*+ plus-minus-button */
/***************************************************************************/
.plus-minus-button {
    justify-content: center;
    display: flex;
    padding: 0;
    margin: 0;
    width: 6rem;
    height: 2rem;
    border-radius: 1rem;
}

.plus-minus-button > .amount {
    text-align: center;
    width: 2rem;
    border-radius: 0;
    padding: 0;
    background-color: #fff;
}

.plus-minus-button > .button-m {
    display: flex;
    width: 2rem;
    padding: 0 0 0 0.2rem;
    margin: 0;
    border-radius: 50% 0 0 50%;
}
.plus-minus-button > .button-p {
    display: flex;
    width: 2rem;
    padding: 0;
    margin: 0;
    border-radius: 0 50% 50% 0;
}

.plus-minus-button > .button-m:focus {
    box-shadow: none;

}

.plus-minus-button > .button-p:focus {
    box-shadow: none;
}

.plus-minus-button-svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
}
/*- plus-minus-button */

/***************************************************************************/
/*+ Tabs ###################*/
/* Inactive tab */
/***************************************************************************/
.myTabsClass {
    margin: 10px 0 35px 0;
    font-size: var(--bakery-fs6);
    background-color: var(--muted-3);
    white-space: nowrap;
}


.myTabsClass li > button {
    width: 220px;
    height: 46px;
    padding: 20px  6px ;
    text-align: center;
    color: var(--secondary);


}

.myTabsClass li > button:hover {
    color: var(--text);
    background-color: var(--white-50);

}

.myTabsClass .nav-link {
    border: solid 1px var(--dark-10);
}

/* Active tab */
.myTabsClass .nav-link.active{
    padding: 13px 21px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.36px;
    text-align: center;
    background-color: var(--white);
    color: var(--dark);
    border: none;
}

/* Disabled tab (like example - not used)*/
.myTabsClass li.disabled > button {
    background-color: lightgray;
    color: darkgray;
}

/*-Tabs ###################*/


/***************************************************************************/
/*+ myCheckBox ###################*/
/***************************************************************************/

.myCheckBoxContainer {
    width: 269px;
    height: 56px;
    margin: 5px 5px 5px 0;
    display: flex;
    flex-wrap: nowrap;
}

.myCheckBoxText, .myCheckBoxText1 {
    width: 17px;
    height: 26px;
    object-fit: contain;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.14px;
    color: #1d2621;
}

.myCheckBoxText1{
    color: #b9b9b9;
}

.myCheckBox{
    width: 30px;
    height: 56px;
    margin-right: 10px;
    display: block;
}

.myCheckBox input, input[type=checkbox]{
    width: 30px;
    height: 30px;
    margin-top: 0;
    background-image: url(img/so.png);
    border-radius: 0;
    border: none;
}
/* Active tab */
.myCheckBox  input:active,
.myCheckBox input:hover,
.myCheckBox  input:focus
{
    box-shadow: none;
    border: none;
}
.myCheckBox  input:checked[type=checkbox], input:checked
{
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    background-image: url(img/po.svg);
}
.myCheckBox .form-check-input[type=checkbox] {
    border-radius: 0;
}
/*- myCheckBox ###################*/

/*+ Form.control*/
.bakery-input, .bakery-input.form-control{
    width: 360px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px var(--muted);
    background-color: var(--white);
    color:var(--dark)
}

.bakery-input:active, .bakery-input:hover, .bakery-input:focus
{
    border: solid 1px var(--danger);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}
.bakery-input.code{
    width: 130px;
}

/*- Form.control*/
