.form{

    margin: 35px auto 40px 20px;
    font-size: var(--bakery-fs6);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);
}

.group{
    width: 380px;
    margin-bottom: 45px;
}



.code, .productName {
    height: 40px;

    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}
.productName{
    width: 380px;
    padding: 0 12px;
}

.productName:active, .productName:hover, .productName:focus,
.code:active, .code:hover, .code:focus
{
    box-shadow: none;
    /*border:none;*/
    border: solid 1px var(--muted);
    background-color: var(--white);
    outline-color: var(--danger);
}

.code{
    width: 130px;
}

.nina{
    width: 130px;
    height: 40px;
    padding: 0 12px;
    font-size: var(--bakery-fs6);
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}

.number{
    width: 50px;
    height: 40px;
    padding: 0 12px;
}
