
.container{
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    width: 825px;
}

.x_close {
    position: relative;
    left: 0px;
    top: 14px;
    width: 22px;
    height: 22px;
    background-color: var(--background);
}

.label{
    margin-bottom: 5px;
    font-size: 12px;
}
