.textarea {
    box-sizing: border-box;
    resize: none;
    line-height: 28px;
    overflow: auto;
    min-height: 55px;
    box-shadow: none;
    padding: 12px  10px 0px 10px;


    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    width: 796px;

    font-size: var(--bakery-fs6);
    color: var(--dark);

    /*line-height: 1.44;*/
}


.textarea:active, .textarea:focus, .textarea:hover, .textarea:focus-visible, .textarea:active:focus-visible {
    box-shadow: none;
    border: solid 1px var(--muted);
    background: var(--white);
    outline-color: var(--danger);
}

