.cont{
    margin: 30px 46px;
    font-size: var(--bakery-fs7);
}
.r1{
    font-size: var(--bakery-fs6);
}

.btn1,.btn2,.btn3{
    width: 80px;
    height: 40px;
    margin: 32px 10px 0 0;
    border-radius: 6px;
    border: solid 1px var(--muted);
    background-color: var(--white);
    text-align: center;
    font-size: var(--bakery-fs6);
    color: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.btn3{
    background-color: var(--primary);
}