.tableLabel{
    font-size: var(--bakery-fs6);
    font-weight: 600;
    margin-bottom: 16px;
}

.tableHeader{
}

.tableHeader th{
    text-align: center;
    font-size: var(--bakery-fs7);
    font-weight: normal;
    padding: 0 8px;
}
.tableHeader th:nth-child(1){
    text-align: left;
}

.tableBody{
}
.tableBody td{
    font-size: var(--bakery-fs7);
    vertical-align: middle;
    padding: 4px 8px;
}
.tableBody td input{
    font-size: var(--bakery-fs7);
    padding: 6px 6px;
}
.tableBody td:nth-child(2){
    transform: scale(0.7);
    vertical-align: middle;
    text-align: center;
}
