
.header{
    vertical-align: middle;
}
.header th{
    font-size: var(--bakery-fs7);
    font-weight: normal;
    color: var(--dark);
    text-align: left;
    white-space: nowrap;
}
.body{
    font-size: var(--bakery-fs6);
}
.body td{

}