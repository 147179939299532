.container{
    display: block;
}
.selectType{
    width: 130px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--white);
}

.selectType {
    display: flex;
    justify-content: space-between;
}

.selectType span {
    width: 85%;
    height: 26px;
    padding-left: 16px;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.36px;
    align-self: center;
    color: #1d2621;
}
.label{
    margin-bottom: 5px;
    font-size: 12px;
}

.selectType img {
    align-self: center;
    margin: 5px;
}
